@font-face {
  font-family: "Gnuolane";
  src: url("../src//assets/fonts/gnuolane.otf") format("otf"),
  url("../src//assets//fonts/gnuolane.otf") format("opentype"),
  }
  @font-face {
    font-family: "Helvetica Neue Black";
    src: url("../src//assets/fonts/HelveticaNeueBlack.otf") format("otf"),
    url("../src//assets//fonts/HelveticaNeueBlack.otf") format("opentype"),
    }
  

.App {
  background-color: #fe0000;
  display: flex;
  align-items: center;
  justify-content: center;
}
.boxCell {
  width: auto;
  max-width: 400px;
  margin-top: -10px;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  flex-direction: column;
}
.boxCellBco {
  background-color:#ffffff;
  width: auto;
  min-height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 40px;
}
.coracao {
  width: 27px;
  height: 23px;
  background-color: #f3f3f3;
}
.quadradinho {
  width: 55px;
  padding-bottom: 6px;
  padding-top: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 1px;
  border-color:#d81b1b;
  background-color: #FFFFFF;
  
}
.pergunta {
  font-family: "Gnuolane";
  font-optical-sizing: auto;
  font-weight: 600;
  border: 1px solid #d81b1b;
  background-color: #f3f3f3;
  font-size: 16px;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 2px;
  padding-bottom: 2px;
}
.perguntaTit {
  font-family: "Gnuolane";
  font-optical-sizing: auto;
  font-weight: 600;
  letter-spacing: 1px;
  font-style: normal;
  background-color: #fe0000;
  border: 2px solid #790c00;
  border-top: 4px solid #790c00;
  font-size: 17px;
  color: #f3f3f3;
  text-align: center;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
}
.quadradinhoTit {
  font-family: "Gnuolane";
  font-optical-sizing: auto;
  font-weight: 600;
  letter-spacing: 1px;
  font-style: normal;
  width: 53px;
  height: 42px;
  color: #f3f3f3;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #790c00;
  background-color: #fe0000;
  border-top: 4px solid #790c00;
  font-size: 17px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
}
.balaozinho {
  position: fixed;
  z-index: 200;
  margin-top: -120px;
  background-color: transparent;
  border: none
}
.QRcode {
  position: absolute;
  z-index: 300;
  top: 0px;
  left: 0px
}
.msgFinal {
  color: #f3f3f3;
  width: 150px;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  background-color: transparent;
  border: none;
}
.paymentClass {
  position: absolute;
  z-index: 400;
  left: 50%;
  top: 0px;
  background-color:#FFFFFF;
  width: 100%;
  height: 100%;
  max-width: 600px;
  margin-top: -10px;
}
.txtComum {
  font-family: "Helvetica Neue Light", sans-serif;
  font-size: 15px;
  text-align: center;
}
.txtTime {
  font-family: "Helvetica Neue Black", sans-serif;
  font-size: 15px;
}
.pixBox {
  background-color: #fdfdfd;
  width: 290px;
  height: 50px;
  border: 2px dashed #ebebeb;
  border-radius: 10px;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
}
.pixTxt {
  font-family: "Helvetica Neue Light", sans-serif;
  font-size: 15px;
  text-align: center;
}
.copyPaste {
  background-color: transparent;
  border: none;
}
.timeBack {
  background-color: #ebebeb;
  width: 330px;
  height: 10px;
  margin-top: 10px;
}
.timeFront {
  background-color: red;

  height: 10px;
}
.maisDezoito {
  position: absolute;
  z-index: 20;
  left: 50%;
  margin-left: -145px;
  top: 203px;
}